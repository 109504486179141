/**
 * Events calendar
 */
import * as qs from 'qs';

import { scrollToElementIfNecessary, trapTabs } from './utilities';

function toggleFiltersMenu({ target }) {
    const filtersMenu = document.querySelector('.events-filters-menu');

    if (target.matches('.events-filters-toggle, .events-filters-toggle *, .events-filters-toggle-text, .events-filters-menu__close, .events-filters-x, .events-filters-x *')) {
        document.querySelector('.events-filters-toggle').classList.toggle('open');
        filtersMenu.classList.toggle('open');
        document.querySelector('body').classList.toggle('events-menu-open');

        if (window.isSmall) {
            trapTabs(filtersMenu);
        }

        return;
    }

    // close filter menu when clicking outside of it
    const filtersToggle = document.querySelector('.events-filters-toggle');

    if (
        !filtersToggle?.matches('.open') ||
        target.matches('.events-filters-toggle') ||
        target.matches('.events-filters-menu') ||
        target.matches('.events-filters-menu *')
    ) {
        return;
    }

    filtersToggle.classList.remove('open');
    filtersMenu.classList.remove('open');
    document.querySelector('body').classList.remove('events-menu-open');
}

function handleToggleKeydown(event) {
    const { target, code } = event;

    if (target.matches('.events-toggle, .events-toggle *') && ['Enter', 'Space'].includes(code)) {
        event.preventDefault();
        target.click();
    }
}

// Special handling for the exhibition toggle button and mobile toggle buttons
function handleToggleButtonClick({ target }) {
    // Remove desktop and mobile exhibition toggle buttons when user deselects one of them
    if (target.matches('.events-toggle__exhibition')) {
        const formEl = target.closest('.events-form');
        formEl.querySelectorAll('.events-toggle__exhibition').forEach((element) => {
            element.remove();
        });
        formEl.requestSubmit();

        return;
    }

    if (!target.matches('.events-toggle__mobile, .events-toggle__mobile *')) {
        return;
    }

    // The toggle buttons on mobile are not actual form elements, so make sure that the corresponding input is toggled
    target.classList.toggle('active');
    const toggleInput = document.querySelector(`.events-toggle input[value="${ target.dataset.tagValue }"]`);
    toggleInput.checked = !toggleInput.checked;

    document.querySelector('form.events-form').requestSubmit();
    updateFilterMenu();
}

function updateFilterMenu() {
    // Make sure the corresponding tag toggle buttons on desktop and mobile have the same state
    document.querySelectorAll('.events-toggle__mobile:not(.events-toggle__exhibition)').forEach((element) => {
        const { tagValue } = element.dataset;
        const toggleInput = document.querySelector(`.events-toggle input[value="${ tagValue }"]`);

        if (toggleInput.checked) {
            element.classList.add('active');
        } else {
            element.classList.remove('active');
        }
    });

    // Check the All filters checkbox if no filters are selected
    const allFiltersButton = document.querySelector('.events-checkbox--all input');
    if (!allFiltersButton) {
        return;
    }

    allFiltersButton.checked = !document.querySelectorAll('.events-toggle input:checked, .events-toggle.active, .events-filters-menu label:not(.events-checkbox--all) input:checked').length;

    if (allFiltersButton.checked) {
        allFiltersButton.setAttribute('disabled', 'true');
    } else {
        allFiltersButton.removeAttribute('disabled');
    }

    // Count the number of selected filters and indicate it
    const selectedFilterMenuItems = document.querySelectorAll('.events-filters-menu label:not(.events-checkbox--all) input:checked').length;
    const filterButtonText = document.querySelector('.events-filters-toggle-text');

    filterButtonText.innerHTML = selectedFilterMenuItems ? `Filters (${ selectedFilterMenuItems })` : 'Filters';
}

function setupTodayExpandable() {
    const todaySection = document.querySelector('.events-today');

    if (!todaySection) return;

    const itemCount = todaySection.querySelectorAll('li').length;
    const moreButton = todaySection.querySelector('.events-today__more');

    if ((window.isSmall && itemCount > 2) || (!window.isSmall && itemCount > 4)) {
        moreButton.style.display = 'block';
    } else {
        moreButton.style.display = 'none';
    }

    document.addEventListener('click', ({ target }) => {
        if (!target.matches('.events-today__more')) return;

        document.querySelector('#today-events-list').classList.add('active');
        target.remove();
    });
}

function handleFilterClear(event) {
    const { target } = event;

    if (!target.matches('.events-checkbox--all') && !target.closest('.events-checkbox--all')) {
        return;
    }

    event.preventDefault();

    const allCheckbox = target.matches('input') ? target : target.querySelector('input');

    setTimeout(() => {
        allCheckbox.checked = true;
    }, 50);

    document.querySelectorAll('.events-toggle input[type="checkbox"], .events-toggle.active, .events-filters-menu ul label.checkbox:not(.events-checkbox--all) input').forEach((element) => {
        element.checked = false;
    });

    target.closest('.events-form').dispatchEvent(new Event('change'));
}

function handleFormChange({ target }) {
    updateFilterMenu();
    target.closest('form.events-form').requestSubmit();
}

// Add the form values to the browser URL so that the user can refresh the page and see the same state
function handleTurboSubmit({ target }) {
    if (!target.matches('.events-form')) {
        return;
    }

    const formData = new FormData(target);
    const fields = {};
    formData.forEach((value, name) => {
        if (!value) return;

        // Handle param arrays so that Rails can parse them, e.g. tags[]
        if (name.endsWith('[]')) {
            const fieldName = name.replace(/\[\]$/, '');
            fields[fieldName] ||= [];
            fields[fieldName].push(value);
        } else {
            fields[name] = value;
        }
    });

    const params = qs.stringify(fields, { arrayFormat: 'brackets', encode: false });
    const queryPrefix = params ? '?' : '';
    const url = [
        window.location.pathname,
        queryPrefix,
        params,
    ].join('');

    window.history.pushState(window.history.state, document.title, url);
}

function handleTurboSubmitEnd({ target }) {
    if (!target.matches('.events-form')) {
        return;
    }

    // Scroll specifically to the events list so controls are directly below the primary nav
    scrollToElementIfNecessary(target.querySelector('.events-list'));
}

function trackClicks({ target }) {
    if (!useAnalytics || !target.closest('.events-form')) {
        return;
    }

    if (target.matches('.events-toggle, .events-toggle *')) {
        const el = target.closest('.events-toggle');
        const value = el.dataset.tagValue || el.querySelector('input')?.value;

        dataLayer.push({
            event: 'buttons',
            label: 'calendar toggle button',
            value,
        });
    } else if (target.matches('.events-filter-label')) {
        const el = target.closest('.events-filter-label');
        dataLayer.push({
            event: 'buttons',
            label: 'calendar filter',
            value: el.querySelector('input')?.value,
        });
    } else if (target.matches('.events-checkbox--all')) {
        dataLayer.push({
            event: 'buttons',
            label: 'calendar filter',
            value: 'all',
        });
    } else if (target.matches('.events-start-over')) {
        dataLayer.push({
            event: 'buttons',
            label: 'calendar start over',
            value: 'true',
        });
    } else if (target.matches('.day-picker-submit')) {
        const value = document.querySelector('.day-picker-selected')?.innerText?.replace('Selected: ', '');
        dataLayer.push({
            event: 'buttons',
            label: 'calendar date submit',
            value,
        });
    } else if (target.closest('.event-calendar-similar ul li > a')) {
        dataLayer.push({
            event: 'buttons',
            label: 'calendar similar events link',
            value: target.closest('a')?.href,
        });
    }
}

export default function setupCalendar() {
    const eventsForm = document.querySelector('.events-form');
    if (eventsForm) {
        setupTodayExpandable();
        document.addEventListener('click', toggleFiltersMenu);
        document.addEventListener('keydown', handleToggleKeydown);
        document.addEventListener('click', handleToggleButtonClick);
        document.addEventListener('click', handleFilterClear);
        document.addEventListener('click', trackClicks);
        document.addEventListener('turbo:submit-start', handleTurboSubmit);
        document.addEventListener('turbo:submit-end', handleTurboSubmitEnd);
        eventsForm.addEventListener('change', handleFormChange);
        updateFilterMenu();
    }
}
