//=================================================
// Collection (Art and Artists)
//=================================================

import { collectionSync, collectionRecordSync } from './utilities';
import { adjustFootnotes } from './footnotes';
import { updateFormControls } from './lists';

// Validate that the date selection (years) make sense
function validateCollectionDates() {
    const dateWrapper = document.querySelector('.collection-overlay-dates');
    if (dateWrapper) {
        const dateInputs = dateWrapper.querySelectorAll('input');
        const dateOne = dateInputs[0].value;
        const dateTwo = dateInputs[1].value;
        const dateOneParsed = parseInt(dateInputs[0].value);
        const dateTwoParsed = parseInt(dateInputs[1].value);

        // Make sure the dates are invalid years, and the inputs not empty, before throwing an error
        if ((Number.isNaN(dateOneParsed) && dateOne !== '') ||
            (Number.isNaN(dateTwoParsed) && dateTwo !== '') ||
            dateOneParsed > dateTwoParsed) {
            dateWrapper.classList.add('collection-overlay-dates--error');
        } else {
            dateWrapper.classList.remove('collection-overlay-dates--error', 'collection-overlay-dates--explanation');
        }
    }
}

function setupClassifications() {
    const wrapper = document.querySelector('.list--collection');
    const classifications = wrapper?.querySelector('.classifications');

    if (classifications) {
        const form = wrapper.querySelector('form');
        const input = wrapper?.querySelector('[name="q[classification_cont]"]');

        // Delegate button clicks since these are added dynamically
        wrapper.addEventListener('click', (e) => {
            if (e.target.matches('.classifications__btn, .classifications__btn *')) {
                const button = e.target.closest('.classifications__btn');
                const buttons = wrapper.querySelectorAll('.classifications__btn');

                // If button is active disable the filter
                if (button.classList.contains('active')) {
                    button.classList.remove('active');
                    button.setAttribute('aria-pressed', 'false');
                    // Set hidden form input
                    input.value = '';
                } else {
                    // ...otherwise set the filter to active (and deactivate any active filters)
                    buttons.forEach((el) => {
                        el.classList.remove('active');
                        el.setAttribute('aria-pressed', 'false');
                    });
                    e.target.classList.add('active');
                    e.target.setAttribute('aria-pressed', 'true');
                    // Set hidden form input
                    input.value = e.target.dataset.value;
                }

                updateFormControls();
                form.requestSubmit();

                if (useAnalytics && e.target.dataset.value) {
                    dataLayer.push({
                        event: 'collection',
                        label: 'filter',
                        value: e.target.dataset.value.toLowerCase(),
                    });
                }
            }
        });
    }
}

function setupColorSearch() {
    // Delegate button clicks since these are added dynamically
    const wrapper = document.querySelector('.list--collection');
    wrapper.addEventListener('click', (e) => {
        if (e.target.matches('.color-selector, .color-selector *')) {
            const buttons = wrapper.querySelectorAll('.color-selector');
            const input = wrapper.querySelector('[name="q[color]"]');
            const colorEl = e.target.closest('.color-selector');

            if (colorEl) {
                // Remove filter if already selected
                if (colorEl.classList.contains('active')) {
                    colorEl.classList.remove('active');
                    colorEl.setAttribute('aria-pressed', 'false');

                    // Set hidden form input
                    input.value = '';

                } else {
                    // Set the filter to active (and deactivate any active filters)
                    buttons.forEach((el) => {
                        el.classList.remove('active');
                        el.setAttribute('aria-pressed', 'false');
                    });
                    colorEl.classList.add('active');
                    colorEl.setAttribute('aria-pressed', 'true');

                    // Set hidden form input
                    input.value = colorEl.dataset.value;

                    if (useAnalytics) {
                        dataLayer.push({
                            event: 'collection',
                            label: 'filter',
                            value: `color (${ colorEl.dataset.label })`,
                        });
                    }
                }

                updateFormControls();
                input.dispatchEvent(new Event('input'));
            }
        }
    });
}

export function setupCollectionSearch() {
    const wrapper = document.querySelector('.list--collection');
    if (wrapper) {
        setupClassifications();
        setupColorSearch();
        updateFormControls();

        // Date validation
        const dateWrapper = wrapper.querySelector('.collection-overlay-dates');
        dateWrapper?.querySelectorAll('.collection-overlay-dates input').forEach((el) => {
            el.addEventListener('change', () => {
                validateCollectionDates();
            });

            // Listen for invalid input and show an explanation of why the key was suppressed
            // See: https://www.w3.org/WAI/WCAG21/Understanding/error-identification.html
            el.addEventListener('keypress', (e) => {
                if (!(e.key === 'Enter' || e.key === 'Escape')) {
                    if (Number.isNaN(parseInt(e.key))) {
                        e.preventDefault();
                        dateWrapper.classList.add('collection-overlay-dates--explanation');
                    } else {
                        dateWrapper.classList.remove('collection-overlay-dates--explanation');
                    }
                }
            });
        });

        // Exhibition filter
        const exhibitionFilter = wrapper.querySelector('.collection-exhibition-filter');
        const exhibitionFilterInput = wrapper.querySelector('.collection-exhibition-filter__input');
        if (exhibitionFilter && exhibitionFilterInput) {
            exhibitionFilter.addEventListener('click', () => {
                exhibitionFilterInput.value = '';
                exhibitionFilterInput.dispatchEvent(new Event('input'));
                exhibitionFilter.remove();
            });
        }

        // Add some usage tracking
        if (useAnalytics) {
            wrapper.querySelectorAll('input, select').forEach((el) => {
                el.addEventListener('input', (e) => {
                    dataLayer.push({
                        event: 'collection',
                        label: 'filter',
                        value: e.target.dataset.label,
                    });
                });
            });

            wrapper.querySelectorAll('.list__randomize').forEach((el) => {
                el.addEventListener('click', () => {
                    dataLayer.push({
                        event: 'collection',
                        label: 'randomize',
                        value: 'true',
                    });
                });
            });

            // Track searches on blur since that should mean someone is done typing
            const searchEl = wrapper.querySelector('input[type=search]');
            if (searchEl) {
                searchEl.addEventListener('blur', () => {
                    if (searchEl.value !== '') {
                        dataLayer.push({
                            event: 'collection',
                            label: 'search',
                            value: searchEl.value,
                        });
                    }
                });
            }
        }
    }
}

// Artists with bios and artworks with visual descriptions have toggling behavior
export function setupCollectionExpandables() {
    document.querySelectorAll('.collection-bio, .collection-detail-expandable').forEach((el) => {
        if (el.dataset.initialized !== 'true') {
            el.dataset.initialized = 'true'; // Only initialize once...
            el.querySelectorAll('.collection-bio__toggle, .collection-detail-expandable__toggle').forEach((toggle) => {
                toggle.addEventListener('click', () => {
                    const expanded = toggle.getAttribute('aria-expanded') === 'true';
                    toggle.setAttribute('aria-expanded', expanded ? 'false' : 'true');

                    if (expanded) {
                        el.classList.remove('active');
                        toggle.innerHTML = 'Read more';
                    } else {
                        el.classList.add('active');
                        toggle.innerHTML = 'Read less';
                        adjustFootnotes();
                    }
                });
            });
        }
    });
}

// Collection sync listeners used in the admin and admin toolbar
export function setupCollectionSync() {
    // Sync recently updated collection records
    const collectionSyncEls = document.querySelectorAll('.collection-sync');
    collectionSyncEls.forEach((el) => {
        if (el.dataset.initialized !== 'true') {
            el.dataset.initialized = 'true'; // Only initialize once...
            el.addEventListener('click', (e) => {
                e.preventDefault();

                collectionSyncEls.forEach((ele) => {
                    ele.disabled = true;
                    ele.classList.add('btn--syncing');
                });
                collectionSync();
            });
        }
    });

    // Sync collection record
    const recordSyncEls = document.querySelectorAll('.collection-record-sync');
    recordSyncEls.forEach((el) => {
        if (el.dataset.initialized !== 'true') {
            el.dataset.initialized = 'true'; // Only initialize once...
            el.addEventListener('click', (e) => {
                e.preventDefault();

                const { url } = el.dataset;
                if (url) {
                    recordSyncEls.forEach((ele) => {
                        ele.disabled = true;
                        ele.classList.add('btn--syncing');
                    });

                    collectionRecordSync(url);
                }
            });
        }
    });
}
