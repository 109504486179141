//=================================================
// Jumplinks
//=================================================
import scrollama from 'scrollama';
import { getNavOffset, isAdmin } from './utilities';
import { getReducedMotion } from './user-preferences';

function scrollToAnchor(el) {
    const anchorOffset = (el.getBoundingClientRect().top + window.scrollY) - getNavOffset();
    const behavior = getReducedMotion() ? 'instant' : 'smooth';
    window.scrollTo({ top: anchorOffset, behavior: behavior });
    // Changing history state in admin is a little wonky with Bootstrap modals (things can shift underneath when the modal opens)
    if (!isAdmin()) {
        window.history.replaceState(window.history.state, document.title, `${ window.location.pathname }${ window.location.search }#${ el.id }`);
    }
}

function getLocalLinks() {
    const localLinks = [];
    const currentURL = new URL(window.location.href);

    // Don't include links in primary navigation
    document.querySelectorAll('main a:not(.footnote-indicator), .admin-jumplinks a').forEach(el => {
        const url = new URL(el.href, currentURL); // Resolve relative URLs
        const href = el.getAttribute('href');
        if (href) {
            const match = document.getElementById(href.slice(1));
            // Check if the link is an anchor or a link to the current page and it matches something
            if (match && url.hash && (url.pathname === currentURL.pathname || url.href === el.href)) {
                localLinks.push(el);
            }
        }
    });

    return localLinks;
}

export default function setupJumplinks() {
    // Setup click handling for all local links
    const localLinks = getLocalLinks();
    localLinks.forEach((el) => {
        const match = document.getElementById(el.getAttribute('href').slice(1));
        if (match && el.dataset.initialized !== 'true') {
            el.dataset.initialized = 'true';

            el.addEventListener('click', (e) => {
                e.preventDefault();

                scrollToAnchor(match);
            });
        }
    })

    // Jumplinks rails have an active state based on scroll depth
    document.querySelectorAll('.jumplinks').forEach((wrapper) => {
        const scroller = scrollama();
        const links = [];
        const steps = [];

        wrapper.querySelectorAll('a').forEach((el) => {
            const match = document.getElementById(el.getAttribute('href').slice(1));
            if (match) {
                links.push(el);
                steps.push(match);
            }
        });

        if (steps.length > 0) {
            scroller
                .setup({ step: steps })
                .onStepEnter((response) => {
                    // When an anchor scrolls into view update active classes
                    links.forEach((el) => {
                        el.classList.remove('active');
                    });

                    if (links[response.index]) {
                        links[response.index].classList.add('active');
                    }
                });
        }
    });

    // Adjust scroll once window has loaded since things may have moved
    if (window.location.hash) {
        const el = document.querySelector(window.location.hash);
        if (el) {
            const anchorOffset = (el.getBoundingClientRect().top + window.scrollY) - getNavOffset();
            window.scrollTo({ top: anchorOffset });
        }
    }
}
