import React from 'react';

import GuideLink from '../../components/GuideLink';
import Indicators from './Indicators';
import { cleanText, createMarkup } from '../../../utilities';
import { getLocationIcons, pushGuideEvent } from '../../utilities';

export default function GuideListItems({ guides, language }) {
    const listItems = [];
    const guidesByLocation = {};

    const handleGuideClick = (guide) => {
        const guideName = cleanText(guide?.attributes.title);

        pushGuideEvent({ guide_name: guideName, label: 'guide', value: guideName });
    };

    if (!guides.length) {
        return (
            <p className="m-0">
                <strong dangerouslySetInnerHTML={ createMarkup(I18n[language].empty_guides) }/>
            </p>
        );
    }

    // Render guides once for each floor they are tagged in
    guides.forEach((guide) => {
        // Institutional guides don't have a real location and get displayed separately
        if (guide.attributes.guide_type === 'institutional') {
            guidesByLocation.institutional ||= {
                guides: [],
                location: null
            };
            guidesByLocation.institutional.guides.push(guide);
        } else {
            guide.attributes.locations.forEach((locationRecord) => {
                const uri = locationRecord.data.attributes.uri;
                guidesByLocation[uri] ||= {
                    guides: [],
                    location: locationRecord.data
                };
                guidesByLocation[uri].guides.push(guide);
            });
        }
    });

    // Sort all the guides with institutional ones at the front
    Object.entries(guidesByLocation)
        .sort(([uriA], [uriB]) => {
            if (uriA === 'institutional') return -1;  // A is institutional, put it first
            if (uriB === 'institutional') return 1;   // B is institutional, put it first
            return uriB.localeCompare(uriA);         // Otherwise sort by URI reverse alphabetically
        })
        .forEach(([locationUri, { guides, location }], locationIndex) => {
            const guidesSortedByDate = [...guides]
                .sort((a, b) => {
                    const dateA = new Date(a.attributes.sort_date);
                    const dateB = new Date(b.attributes.sort_date);
                    return dateB - dateA;
                });

            guidesSortedByDate.forEach((guide, index) => {
                const artwork = guide.attributes.guide_type === 'artwork';
                const disabled = guide.attributes.guide_stops.length === 0;
                const showLocation = index === 0 && guide.attributes.guide_type !== 'institutional';

                let media;
                if (guide.attributes.primary_media_html) {
                    media = <div className={ `guide-list-item__media${ artwork ? ' artwork' : '' }` }
                                 dangerouslySetInnerHTML={ createMarkup(guide.attributes.primary_media_html) }/>;
                }

                let eyebrow;
                if (artwork) {
                    eyebrow = <p className="guide-list-item__type body-medium-bold">
                        { I18n[language][guide.attributes.guide_type] }
                    </p>;
                }

                const guideMainContent =
                    <>
                        { media }
                        { eyebrow }
                        <h2 className={ `guide-list-item__title${ disabled ? ' gray' : '' }` }
                            dangerouslySetInnerHTML={ createMarkup(guide.attributes.title) }/>
                        <p className={ `guide-list-item__indicators${ disabled ? ' gray' : '' }` }>
                            { guide.attributes.guide_stops.length > 0
                                ? <Indicators guide={ guide } language={ language }/>
                                : I18n[language].empty_guides
                            }
                        </p>
                    </>;

                let guideMain;
                if (guide.id) {
                    guideMain =
                        <GuideLink
                            to={ `${ guide.id }?language=${ language }` }
                            onClick={ () => handleGuideClick(guide) }
                            scrollToTop={ true }
                            className="guide-list-item__main"
                        >
                            { guideMainContent }
                        </GuideLink>;
                } else {
                    guideMain =
                        <div className="guide-list-item__main">
                            { guideMainContent }
                        </div>;
                }

                let locationIcons;
                if (showLocation) {
                    locationIcons =
                        <div className="guide-location-icons guide-location-icons--list">
                            { getLocationIcons(language, guide.attributes.locations, location.attributes.uri) }
                        </div>
                }

                const listItem =
                    <li key={ `${ locationIndex }-${ index }` }
                        className={ `guide-list-item${ showLocation ? ' guide-list-item--line' : '' }` }>
                        <hr className="guide-list-item__line"/>
                        <div className={ `guide-list-item__floor${ showLocation ? ' active' : '' }` }>
                            <h3 className="guide-list-item__floor-number m-0">
                                { showLocation ? location.attributes.short_name : '\u00A0' }
                            </h3>
                            { locationIcons }
                        </div>
                        { guideMain }
                    </li>;

                listItems.push(listItem);
            });
        });

    return <>{ listItems }</>;
}
